import { render, staticRenderFns } from "./Responsible.vue?vue&type=template&id=4d830c22"
import script from "./Responsible.vue?vue&type=script&lang=js"
export * from "./Responsible.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports