<template>

  <div class="body1">

    <div>
      <div class="bd-callout bd-callout-default">
        <p class="left">
          <button class="btn btn-link btn-sm black-txt" @click="goBack">
            <arrow-left-icon></arrow-left-icon>
          </button>
          <span class="right">
              HELP
          </span>
        </p>
      </div>
    </div>
    
    <pre></pre>
    
    <div>
      <div class="bd-callout bd-callout-default list">
        <h3>Help</h3>
        <p class="bold">How do I register?</p>
        <p>
          To join via SMS, kindly send, “BUSTA” to 29688. You will receive a confirmation message from 29688 confirming that you are now registered and ready to bet.
        </p>
        <p>
          To register online, visit <a style="text-decoration: underline;" href="https://Ponyoka.ke/">Ponyoka.ke</a> and select ‘SIGN UP’. Enter your phone number and the password you would like to use then finally click on ‘REGISTER’.
        </p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">How much does it cost to register?</p>
        <p>It is free to register online</p>
        <p>
          For SMS registration mobile SMS charges of Ksh. 2/- will apply for sending the message
        </p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">How do I deposit money into my Ponyoka Account?</p>
        <ol>
          <li>Go to your Mpesa menu</li>
          <li>Select Lipa na Mpesa</li>
          <li>Enter the PayBill Number 222563</li>
          <li>Account Number: Ponyoka</li>
          <li>Enter the amount</li>
          <li>MPESA PIN and choose ok</li>
          <li>The funds will then be available in your Ponyoka-wallet</li>
        </ol>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">How do I place a BET?</p>
        <p>To place single bet send “GAMEID#PICK#AMOUNT TO 29688” To place multi-bet send “GAMEID#PICK#GAMEID#PICK#AMOUNT TO 29688”</p>
        <p>The mobile SMS charges of Ksh. 2/- will apply for sending the message</p>
        <p>Log into <a style="text-decoration: underline;" href="https://Ponyoka.ke/">Ponyoka.ke</a>. Select preferred matches, click on PLACE BET. You will receive a confirmation message that your bet has been placed.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">What is this message I receive after placing a bet?</p>
        <p>The message received after placing a bet is a bet confirmation message - Bet slip or BETID used for identifying each bet.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">What do I do after I receive the Bet slip or BETID?</p>
        <p>Once you have placed a bet and received a confirmation message with your predictions from the short code 29688, you can either choose to continue placing more bets in order to increase your chances of winning or wait for resulting with your already placed bet.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">How do I check the balance in my account?</p>
        <p>Send the word Bal to 29688.</p>
        <p>The mobile SMS charges of Ksh. 2/- will apply for sending the message.</p>

        <!--++++++++++++++++++++++++++++++++++++++++++++++++-->

        <p class="bold">How do I withdraw money from my account?</p>
        <p>Send the word W#Amount to 29688. E.g. To withdraw 300 send the word W#300 to 29688.</p>
        <p>To withdraw via Web</p>
        <ol>
          <li>Open your web browser and type in:  <a style="text-decoration: underline;" href="https://Ponyoka.ke/">Ponyoka.ke</a></li>
          <li>Select login.</li>
          <li>Enter your number and password to access your account.</li>
          <li>Select ‘Withdrawal’ Tab on the right side of your device.</li>
          <li>Enter the amount you wish to withdraw (minimum 100/=)</li>
          <li>Select ‘Request Withdrawal’</li>
        </ol>


        <p class="bold">Are there withdrawal charges?</p>
        <p>MPESA charges will apply </p>
        <p>The mobile SMS charges of Ksh. 2/- will apply for sending the message.</p>

        <p class="bold">What is the usual withdrawal processing time?</p>
        <p>Withdrawals are paid instantly (up to a limit of KES 10,000 per day). If your withdrawal does not process instantly, please contact us on 0112899208.</p>

        <p class="bold">If I place a bet and there's a balance in my  Ponyoka account, what happens to the rest of my money?</p>
        <p>
          Your balance remains in your account until you are ready to play again.
        </p>

        <p class="bold">Is there a limit to how many bets I can place?</p>
        <p>NO...you can place as many bets as you wish </p>

        <p class="bold">How many selections can I add to my betslip?</p>
        <p>The maximum number of selections you can add to your bet slip is 19</p>

        <p class="bold">What is a  Ponyoka freebet?</p>
        <p>
          A Free Bet entitles you to place a bet at our expense! Select your bet, we pay the stake and you keep the winnings in your Ponyoka account. e.g. You place your Ksh 25/= Free Bet at odds of 4.50: if your selection wins, you get Ksh 87/= (stake of Ksh 25/= x odds of 4.50 = Ksh 112, minus Kshs 25/= ) without any risk on your side.
        </p>

        <p class="bold">How do I redeem my free bet?</p>
        <ol>
          <li> Ponyoka offers a Freebet worth KSHS 25/= to all new players. It’s easy to make your Freebet:</li>
          <li>Enter your phone number</li>
          <li>Choose your account password (6 or more characters)</li>
          <li>Select 'Submit Freebet' to place the bet.</li>
        </ol>
        <p>
          Your  Ponyoka account will be created and your Freebet will be placed. Please don't forget to verify your account once you receive our SMS code; unverified accounts will lose their Freebet after 7 days.
        </p>

        <p class="bold">How do I play JACKPOT?</p>
        <p>You can play the jackpot via online or SMS. The JACKPOT has 13 Games; each JACKPOT ticket is KES 49</p>
        <p>To play Online: login to your acc, navigate to the JACKPOT tab.</p>
        <p>Make selections then click place “Place bet & win”</p>

        <p class="bold">How long are the free bets valid?</p>
        <p>Free bets are valid for 7 days </p>

        <p class="bold">What should I do if I wish to dispute the resulting of a bet?</p>
        <p>
          You should contact our Customer Service Team providing your mobile phone number, the Bet ID in question and and all details concerning the dispute.
        </p>

        <p class="bold">How do I cancel a bet?</p>
        <p>To cancel a bet, send ‘CANCEL#BET I. D’ e.g. ‘CANCEL#12345678’ to 29688. The cancellation is allowed only within 10 minutes of bet placement and before a game starts.</p>
        <p>You can only cancel 3 Bet IDs per day and this is ONLY available on SMS platform.</p>
        <p>Note: Live bets and Jackpot Bets once placed cannot be cancelled.</p>

        <p class="bold">What are Void Matches?</p>
        <p>When a game is cancelled/postponed/suspended/abandoned, kindly be patient and wait for at least 24 hours for the match to be rescheduled.</p>
        <p>If that does not happen within that period, we void that match and the stake/bet amount is refunded in case it was only a Single Bet. For a Multi bet, the affected match will be settled with an odd of 1.0.</p>

        <p class="bold">Forgotten Password</p>
        <p>If you have forgotten the password to your  Ponyoka account:</p>
        <p>Kindly visit our website:  <a style="text-decoration: underline;" href="https://Ponyoka.ke/">Ponyoka.ke</a>, click on ‘LOGIN’, and then click of ‘Forgot’ option.</p>
        <p>Enter your phone number and click on ‘Get Reset Code’ option and we shall send a reset code to your phone via SMS- please note this is NOT the password.</p>
        <p>Key in the 4-digit code in the ‘Code’ box- type in a preferred password-confirm the password.</p>
        <p>Ensure all required fields are filled in, and then click on ‘Change Password’. Your screen will display a ‘Password successfully reset’ –kindly log in and access your  Ponyoka account.</p>


        <p class="bold">How to check your Bet Outcome</p>
        <p>To check your Bet outcomes:</p>
        <p>On our Website:  <a style="text-decoration: underline;" href="https://Ponyoka.ke/">Ponyoka.ke</a></p>
        <p>Click on ‘My Bets’</p>

        <p class="bold">Where can I find Terms & Conditions?</p>
        <p>At the bottom of the website (footer) you will find our provisions.</p>

        <h3>APPLICABLE TAXES.</h3>
        <p></p>
        <p class="bold">What is 20% Withholding Tax?</p>
        <p>Withholding tax on betting was passed and introduced into the Finance Act; it is 20% of your net possible win i.e. Total possible win - stake.</p>
        <p class="bold">Why are you implementing this?</p>
        <p>This is a legal requirement that we have implemented in compliance with the regulatory rules.</p>
        <p class="bold">How can I contact  Ponyoka?</p>
        <p>Customer Service Contact Number: 0112899208</p>
        <p>Customer Service Email: support@Ponyoka.co.ke</p>
        <p>Twitter: <a href="https://twitter.com/Ponyoka" target="_blank">@Ponyoka</a></p>
        <p>Facebook:  <a href="https://web.facebook.com/Ponyoka" target="_blank">Ponyoka</a></p>
        <p>Instagram:  <a href="https://www.instagram.com/Ponyoka/" target="_blank">Ponyoka</a></p>

      </div>
    </div>

    <BottomFooter></BottomFooter>

    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Help',
  components: {
    BottomFooter,
    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","help");
    this.reloadProfile();

  }

}
</script>